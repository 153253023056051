/* General */

.uc--wrapper {
  position: relative;
  margin-bottom: 50px !important;
  display: block !important;
}

.uc--wrapper img {
  max-width: 100%;
  font-size: 0;
}

/* Carousel */

.uc--wrapper .uc--scroll-area {
  display: flex;
  overflow: hidden;
}

.uc--wrapper .uc--scroll-area .uc--slide {
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 100%;
  width: 0;
}

.uc--wrapper .uc--scroll-area .uc--slide .uc--content {
  height: 100%;
}

.uc--wrapper .uc--scroll-area .uc--slide:not(.active) {
  pointer-events: none;
}

.uc--wrapper .uc--indicators .uc--arrow {
  position: relative;
  z-index: 99;
  background: none;
  border: none;
  cursor: pointer;
}

.uc--wrapper .uc--indicators.left-right .uc--scroll-left {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-100%, -50%);
}

.uc--wrapper .uc--indicators.left-right .uc--scroll-right {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(100%, -50%);
}

.uc--wrapper .uc--indicators[class*="left"] {
  text-align: left;
}

.uc--wrapper .uc--indicators[class*="right"] {
  text-align: right;
}

.uc--wrapper .uc--indicators[class*="center"] {
  text-align: center;
}

.uc--wrapper .uc--indicators[class*="bottom"] .uc--arrow {
  margin-top: 50px;
}

.uc--wrapper .uc--indicators[class*="bottom"].hide-dots .uc--arrow {
  margin-top: 20px;
}

.uc--wrapper .uc--indicators .uc--dots {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  position: absolute;
  z-index: 99;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
}

.uc--wrapper .uc--indicators .uc--dots .uc--dot {
  display: block;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  border-radius: 100px;
  background: #c4c4c4;
}

.uc--wrapper .uc--indicators .uc--dots .uc--dot.active {
  background: #000000;
  opacity: 0.5;
  position: absolute;
  left: 0;
  top: 0;
}

/* Faded Edges & Spacing */

.uc--wrapper .uc--scroll-area {
  position: relative;
  padding: 7vw;
  margin: -7vw;

  mask-image: linear-gradient(to bottom, transparent 0vw, #000 6.5vw),
    linear-gradient(to top, transparent 0vw, #000 6.5vw),
    linear-gradient(to right, transparent 6.5vw, #000 8vw),
    linear-gradient(to left, transparent 6.5vw, #000 8vw);
  mask-size: 100% 100%;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-composite: intersect;
  mask: linear-gradient(to bottom, transparent 0vw, #000 6.5vw),
    linear-gradient(to top, transparent 0vw, #000 6.5vw),
    linear-gradient(to right, transparent 6.5vw, #000 8vw),
    linear-gradient(to left, transparent 6.5vw, #000 8vw);
  -webkit-mask: linear-gradient(to bottom, transparent 0vw, #000 6.5vw),
    linear-gradient(to top, transparent 0vw, #000 6.5vw),
    linear-gradient(to right, transparent 6.5vw, #000 8vw),
    linear-gradient(to left, transparent 6.5vw, #000 8vw);
  -webkit-mask-size: 100%;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-composite: source-in;
  -webkit-mask-composite: intersect;
}
